import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from '@appEnv/environment';
import { apiUrls } from '@appEnv/apiurls';

@Injectable({
  providedIn: 'root'
})

export class alertService {
    constructor(private http: HttpClient) { }
    baseURL = environment.parentUnit;
    devisionUrl = environment.devisionUrl;
    licenseURL = environment.licneseBaseUrl;
    masterURL = environment.devisionUrl;
    notificationURL = environment.notificationURL;


    getActiveEntityType(postParam): Observable<any> {
        const url = this.baseURL + apiUrls.getActiveEntityType;
        return this.http.post(url, postParam);
    }

    addAlert(data): Observable<any> 
    {
        const url = this.baseURL + 'alert';
        return this.http.post(url, data);
    }

    getdetails(): Observable<any> {//work
        //const url = this.licenseURL + 'license/category/DISTILLERY';
        const url = this.licenseURL + 'license/category/BOTTLING';// nisha ma'am
        return this.http.get(url);
    }

    getlicensebynumber(entity,number){
        const url = this.licenseURL + 'license/category/'+entity+'?licenseNumber='+number;
        return this.http.get(url);
    }

    getapplicationnumber(data): Observable<any> {
        // const url = this.baseURL + 'generateApplicationNumber?registrationType='+ data;
        // return this.http.post(url, data);

        const url = this.baseURL + 'generateApplicationNumber?entityType=' +'PD' + '&registrationType=' + data.registrationType ;+ '&licenseNumber=' + data.licenseNumber;
        return this.http.post(url, {});

    }

    search(payload): Observable<any> {
            // const url = 'http://localhost:3501/scmProduction/alert/lazySearch';
        const url = this.baseURL + 'alert/lazySearch';
        return this.http.post(url, payload);
    }

    getAlertById(id): Observable<any> {
        const url = this.baseURL + 'alert/' + id;
        //const url ='http://65.0.150.210:3501/scmProduction/enaPurchaseOrder/' + id;
        return this.http.get(url);
    }


    getStatusMasters() {
        const url = this.masterURL + "api/masterValues/findDropDownMaster?dropDownKey=WHOLESALE_STATUS_INDENTREQ"
        return this.http.get(url)
    }
    alertDetailSearch(payload): Observable<any> {
        const url = this.notificationURL + 'api/alert/findAlertDetailsList';
        return this.http.post(url, payload);
    }

    findByAlertId(id): Observable<any> {
        const url = this.notificationURL + 'api/alert/findByAlertId?alertId=' + id;
        return this.http.get(url);
    }
}